import { Controller } from '@hotwired/stimulus';

import GPX from 'ol/format/GPX';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Select from 'ol/interaction/Select';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import { createEmpty as createEmptyExtent, extend } from 'ol/extent';
import { pointerMove } from 'ol/events/condition';
import {
  Group as LayerGroup,
  Tile as TileLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import { Stroke, Style } from 'ol/style';

const getLoopLayerStyle = (color) =>
  new Style({
    stroke: new Stroke({
      color,
      width: 3,
    }),
  });

const loopLayerHighlightStyle = new Style({
  stroke: new Stroke({
    width: 6,
  }),
});

/**
 * Render a map with route loops using OpenLayers.
 */
export default class extends Controller {
  static classes = ['selected'];

  static targets = ['map', 'select'];

  static values = {
    loops: Array,
    selected: {
      type: Number,
      default: 0,
    },
  };

  connect() {
    const rasterLayer = new TileLayer({
      source: new OSM({
        attributions:
          'Donn&eacute;es &copy; <a href="https://www.openstreetmap.org/copyright" rel="noopener">OpenStreetMap</a> ' +
          '- Rendu <a href="https://openstreetmap.fr" rel="noopener">OSM France</a>',
        url: 'https://{a-c}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
        minZoom: 1,
        maxZoom: 20,
      }),
    });

    // Create a group with all loop layers
    this.loopLayersGroup = new LayerGroup({
      layers: this.loopsValue.map(
        (loop) =>
          new VectorLayer({
            source: new VectorSource({
              url: loop.gpxFile,
              format: new GPX(),
            }),
            style: getLoopLayerStyle(loop.color),
            properties: {
              pk: loop.pk,
              name: loop.name,
              color: loop.color,
            },
          }),
      ),
    });

    // Create a Select interaction to highlight a loop on mouse over
    this.selectPointerMove = new Select({
      condition: pointerMove,
      style: this.getLoopLayerHighlightStyle.bind(this),
      hitTolerance: 2,
    });
    this.selectPointerMove.on('select', ({ deselected, selected, target }) => {
      if (selected.length) {
        const selectedLayer = target.getLayer(selected[0]);
        // Set layers opacity depending on the selected feature's layer
        this.loopLayersGroup.getLayers().forEach((layer) => {
          layer.setOpacity(
            layer.get('pk') === selectedLayer.get('pk') ? 1 : 0.4,
          );
        });
      } else if (deselected.length) {
        // Reset layers opacity as one of their feature has been deselected
        this.loopLayersGroup.getLayers().forEach((layer) => {
          layer.setOpacity(1);
        });
      }
    });

    // Create and render the map
    this.map = new Map({
      layers: [rasterLayer, this.loopLayersGroup],
      target: this.mapTarget,
      view: new View({
        constrainResolution: true,
        center: [307280.75641121273, 6567342.190149578],
        zoom: 13,
      }),
    });
    this.map.addInteraction(this.selectPointerMove);

    // Center the view to fit all loops layers
    this.map.once('loadend', () => {
      this.centerView();
    });
  }

  // Actions

  toggle({ params: { loop } }) {
    this.selectedValue = this.selectedValue === loop ? 0 : loop;
  }

  reset() {
    this.loopLayersGroup.getLayers().forEach((layer) => {
      layer.setVisible(true);
    });

    this.selectTargets.forEach((element) => {
      element.classList.remove(...this.selectedClasses);
    });
  }

  // Callbacks

  selectedValueChanged(pk, previousPk) {
    if (pk === previousPk) return;

    if (pk) {
      this.loopLayersGroup.getLayers().forEach((layer) => {
        layer.setVisible(layer.get('pk') === pk);
      });

      this.selectTargets.forEach((element) => {
        const elementPk = parseInt(
          element.getAttribute(`data-${this.identifier}-loop-param`),
          10,
        );

        if (elementPk === pk) {
          element.classList.add(...this.selectedClasses);
        } else {
          element.classList.remove(...this.selectedClasses);
        }
      });
    } else if (previousPk) {
      this.reset();
    }
  }

  // Private

  getLoopLayerHighlightStyle(feature) {
    const color = this.selectPointerMove.getLayer(feature).get('color');
    loopLayerHighlightStyle.getStroke().setColor(color);
    return loopLayerHighlightStyle;
  }

  centerView() {
    const extent = createEmptyExtent();

    this.loopLayersGroup.getLayers().forEach((layer) => {
      extend(extent, layer.getSource().getExtent());
    });

    this.map
      .getView()
      .fit(extent, { padding: [10, 10, 10, 10], duration: 300 });
  }
}
