import MobileMenu from './mobile-menu';

function initComponent(ComponentClass) {
  const items = document.querySelectorAll(ComponentClass.selector);
  items.forEach((item) => new ComponentClass(item));
}

export const initComponents = () => {
  initComponent(MobileMenu);
};
