// Voir : https://fontsource.org/fonts/poppins
import '@fontsource/fira-sans/latin-400.css';
import '@fontsource/fira-sans/latin-400-italic.css';
import '@fontsource/fira-sans/latin-500.css';
import '@fontsource/fira-sans/latin-700.css';

import '@fontsource/dosis/latin-400.css';
import '@fontsource/dosis/latin-700.css';

import { Application } from '@hotwired/stimulus';

import { initComponents } from './components/index';
import { controllerDefinitions } from './controllers/index';
import { isTrackingEnabled } from './controllers/tracking-opt-out';

window.Stimulus = Application.start();
window.Stimulus.load(controllerDefinitions);

window.isTrackingEnabled = isTrackingEnabled;

document.addEventListener('DOMContentLoaded', () => {
  initComponents();
});
