import { Controller } from '@hotwired/stimulus';

/**
 * Toggle contextual overlays for displaying lists of links.
 *
 * @example - toggle a submenu
 * <div data-controller="dropdown">
 *   <button
 *     type="button"
 *     aria-label="Toggle the menu"
 *     aria-controls="submenu"
 *     aria-expanded="false"
 *     data-action="dropdown#toggle:stop keydown.esc->dropdown#close click@window->dropdown#close"
 *     data-dropdown-target="toggler"
 *   >
 *     Toggle
 *   </button>
 *   <div id="submenu" data-dropdown-target="content" hidden>
 *     <a href="#">Account settings</a>
 *     <a href="#">Support</a>
 *     <a href="#">License</a>
 *   </div>
 * </div>
 */
export default class extends Controller {
  static classes = ['closed', 'opened', 'openedContent'];

  static targets = ['content', 'toggler'];

  static values = {
    closed: { default: true, type: Boolean },
  };

  // Actions

  close(event) {
    // Prevent closing from the content in case of a window catch all
    if (event && this.contentTarget.contains(event.target)) {
      return;
    }

    this.closedValue = true;
  }

  open() {
    this.closedValue = false;
  }

  toggle() {
    this.closedValue = !this.closedValue;
  }

  // Callbacks

  closedValueChanged(shouldClose, previouslyClosed) {
    if (previouslyClosed === shouldClose) return;

    if (shouldClose) {
      this.togglerTarget.setAttribute('aria-expanded', 'false');

      this.element.classList.add(...this.closedClasses);
      this.element.classList.remove(...this.openedClasses);

      this.contentTarget.classList.remove(...this.openedContentClasses);
      this.contentTarget.hidden = true;
    } else {
      this.togglerTarget.setAttribute('aria-expanded', 'true');

      this.element.classList.remove(...this.closedClasses);
      this.element.classList.add(...this.openedClasses);

      this.contentTarget.classList.add(...this.openedContentClasses);
      this.contentTarget.hidden = false;
    }
  }
}
