import { createFocusTrap } from 'focus-trap';

const ESCAPE_KEY = 'Escape';

const CLASS_NAME_NO_SCROLL = 'overflow-y-hidden';

const DATA_OPENED_CLASS = 'data-mobile-menu-opened-class';

const SELECTOR_MENU = '[data-mobile-menu]';

class MobileMenu {
  static get selector() {
    return SELECTOR_MENU;
  }

  constructor(node) {
    this.node = node;
    this.header = this.node.previousElementSibling;
    this.body = document.querySelector('body');
    this.toggler = document.querySelector(`[aria-controls~="${this.node.id}"]`);

    this.openedClasses = this.node.getAttribute(DATA_OPENED_CLASS).split(' ');

    this.state = {
      open: false,
    };

    this.focusTrap = createFocusTrap([this.header, this.node]);

    this.bindEventListeners();
  }

  bindEventListeners() {
    this.toggler.addEventListener('click', () => {
      this.toggle();
    });

    this.node.addEventListener('keydown', (e) => {
      if (e.key === ESCAPE_KEY) {
        this.close();
      }
    });
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    const { bottom } = this.header.getBoundingClientRect();
    this.node.style.paddingTop = `${bottom}px`;

    this.toggler.setAttribute('aria-expanded', 'true');
    this.body.classList.add(CLASS_NAME_NO_SCROLL);
    this.node.classList.add(...this.openedClasses);

    this.state.open = true;
    this.focusTrap.activate();
  }

  close() {
    this.toggler.setAttribute('aria-expanded', 'false');
    this.body.classList.remove(CLASS_NAME_NO_SCROLL);
    this.node.classList.remove(...this.openedClasses);

    this.state.open = false;
    this.focusTrap.deactivate();
  }
}

export default MobileMenu;
