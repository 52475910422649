import DismissibleController from './dismissible';
import DropdownController from './dropdown';
import FileInputController from './file-input';
import RouteMapController from './route-map';
import TrackingOptOutController from './tracking-opt-out';

export const controllerDefinitions = [
  { controllerConstructor: DismissibleController, identifier: 'dismissible' },
  { controllerConstructor: DropdownController, identifier: 'dropdown' },
  { controllerConstructor: FileInputController, identifier: 'file-input' },
  { controllerConstructor: RouteMapController, identifier: 'route-map' },
  {
    controllerConstructor: TrackingOptOutController,
    identifier: 'tracking-opt-out',
  },
];
