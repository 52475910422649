import { Controller } from '@hotwired/stimulus';

/**
 * Remove an element from the DOM.
 *
 * @example - dismiss an alert
 * <div role="alert" data-controller="dismissible">
 *   <p>Some message</p>
 *   <button type="button" data-action="click->dismissible#dismiss">
 *     Close
 *   </button>
 * </div>
 */
export default class extends Controller {
  static values = { focused: String };

  // Actions

  dismiss() {
    const focusedElement = this.focusedValue
      ? document.querySelector(this.focusedValue)
      : this.element.parentNode;

    this.element.remove();

    focusedElement.focus();
  }
}
