import { Controller } from '@hotwired/stimulus';

/**
 * Retrieve and display the selected file's name of a file input.
 *
 * @example - update the selected file's name
 * <div data-controller="file-input" data-file-input-emty-value="No selected file." data-file-input-current-value="some-file.pdf">
 *   <input type="file" data-file-input-target="input">
 *   <span data-file-input-target="filename"></span>
 *   <button type="button" data-action="click->file-input#clear">Clear</button>
 * </div>
 */
export default class extends Controller {
  static classes = ['clearable'];

  static targets = ['input', 'filename', 'clear'];

  static values = {
    current: String,
    empty: String,
  };

  // Actions

  clear() {
    this.inputTarget.value = '';

    // 'change' event is not dispatched when value is set manually
    this.currentValue = '';

    if (this.hasClearTarget) {
      this.clearTarget.value = '1';
    }
  }

  // Callbacks

  connect() {
    this.inputTarget.addEventListener('change', () => {
      this.currentValue =
        this.inputTarget.files.length > 0 ? this.inputTarget.files[0].name : '';
    });
  }

  currentValueChanged(value) {
    if (value) {
      this.filenameTarget.textContent = value;
      this.element.classList.add(...this.clearableClasses);
    } else {
      this.filenameTarget.textContent = this.emptyValue;
      this.element.classList.remove(...this.clearableClasses);
    }
  }
}
